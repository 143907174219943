import Vue from "vue";
import vuex from "vuex";
import pathify from "./pathify";
import { make } from "vuex-pathify";
import createPersistedState from "vuex-persistedstate";

Vue.use(vuex);
const state = {
  lotteryid: null,
  user: null,
  lottery: null,
  lotterytotalamount: null,
  round: null,
  israndombill: null,
  randombilldigit: null,
  randombillamount: null,
  randomcount: null,
  lotteryticket: null,
  paymentway: null,
  machineid: null,
  device: null,
  otpsignature: null,
  publicKey: null,
  privateKey: null,
  isshowpopup: null,
  predigits: null,
  loading: null,
  location: null,
  selectedproducts: [],
  favoritedigits: [],
};
const mutations = make.mutations(state);
const store = new vuex.Store({
  state,
  mutations,
  plugins: [
    pathify.plugin,
    createPersistedState({
      key: "akatsuperwin",
      storage: window.localStorage,
    }),
  ],
});

global.store = store;
export default store;
